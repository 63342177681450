import React from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import _ from 'lodash';

import { makeStyles } from '@material-ui/core/styles';

import PositionCard from './components/PositionCard';
import PositionCardOwned from './components/PositionCardOwned';
import CollapseButton from './CollapseButton';
import PositionEdit from './PositionEdit';
import PositionDelete from './PositionDelete';
import PositionReply from './PositionReply';
import PositionParent from './PositionParent';
import PositionHeartCast from './PositionHeartCast';
import PositionThumbRate from './PositionThumbRate';
import PositionDimensionsCast from './PositionDimensionsCast';
import ClaimCreate from '../claims/ClaimCreate';
import ParticipantLabel from '../participants/ParticipantLabel';

import { unfocusPosition } from '../store/positions.slice';

const useStyles = makeStyles(theme => ({
  root: {
    // border: 'solid black 1px', // DEBUG
  },

}));

function PositionCardContainer(props) {
  const {
    className,
    position,
    expanded,
    side,
    authUser,
    focusedId,
    onFocus,
    children,
  } = props;
  const classes = useStyles();

  // @todo trasform this in a function
  const ownerId = position.metadata.createdBy;
  const owned = Boolean(authUser) && ownerId === authUser.uid;
  // ---------------------------------
  const parentId = _.get(position, 'parentId', null);
  // const Component = PositionCard;
  const Component = owned ? PositionCardOwned : PositionCard;
  const dimensionsVariant = owned ? 'white' : 'colored';

  // console.log('PositionCardContainer', props); // DEBUG

  return (
    <Component
      className={clsx(classes.root, className)}
      entity={position}
      side={side}

      expanded={expanded}
      expandButton={<CollapseButton positionId={position.id}/>}
      focused={position.id === focusedId}
      onFocus={onFocus}

      owner={<ParticipantLabel userId={ownerId} />}
      parent={<PositionParent parentId={parentId} />}
      // reflect={<PositionDimensionsCast position={position} />}
      actionPanelLeft={<>
        {/* <PositionCreateShortcut position={position} /> */}
        {/* <PositionDimensionsCast position={position} /> */}
      </>}
      actionPanelRight={<>
        <PositionEdit position={position} />
        <PositionDelete position={position} />
        {/* <PositionReply parent={position} /> */}
        {/* <PositionThumbRate position={position} /> */}
        <PositionHeartCast position={position} />
        <PositionDimensionsCast position={position} variant={dimensionsVariant} />
      </>}
      createClaim={<ClaimCreate position={position} />}
    >
      {children}
    </Component>
  );
}

const mapStateToProps = (state, ownProps) => {
  const positionId = ownProps.position.id;
  const positionsState = state.discuss.positions;
  const positionCards = state.discuss.layout.positionCards;
  return {
    expanded: !_.get(positionCards, `[${positionId}].collapsed`, false),
    focusedId: positionsState.focusedId,
    authUser: state.auth.profile,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onFocus: () => dispatch(unfocusPosition()),
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PositionCardContainer);
